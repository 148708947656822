<template>
  <!-- FAQ Area End Here -->
  <div class="faq-area faq-style-common faq-style-2">
    <div class="container">
      <div class="section-area">
        <h2 class="section-title">Question & Answer</h2>
        <p class="section-desc">A cinch for Professors and a breeze for students</p>
      </div>

      <div class="row text-center">
        <div class="row col-lg-6">
          <h3 class="faq-title col-12">Researchers FAQ</h3>
          <div class="col-lg-12">
            <div v-for="(item, index) in orders" :key="index" class="col-lg-12">
              <div class="faq-item">
                <h3 class="faq-title">{{ item.title }}</h3>
                <p class="faq-desc">{{ item.desc }}</p>
                <p class="faq-desc">{{ item.desc2 }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row col-lg-6">
          <h3 class="faq-title col-12">Students FAQ</h3>
          <div class="col-lg-12">
            <div v-for="(item, index) in students" :key="index" class="col-lg-12">
              <div class="faq-item">
                <h3 class="faq-title">{{ item.title }}</h3>
                <p class="faq-desc">{{ item.desc }}</p>
                <p class="faq-desc">{{ item.desc2 }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: [
        {
          title: "How do I get an account?",
          desc:
            "Register an account on the website using your mobile phone number and email address.",
        },
        {
          title: "What kind of research can I do?",
          desc:
            "Surveys, questionnaires, mobile games, perception experiments and true/false quick tests. Basically everything!",
        },
        {
          title: "Why choose a mobile research platform?",
          desc:
            "Since COVID, education is increasingly with no signs of slowing. Furthermore, there is evidence that mobile phones are more common than TVs and even toilets in some parts of the world.",
        },
        {
          title: "Do I need an IRB?",
          desc:
            "Yes, any research must be approved by your Institution’s Review Board before use on the GameChanger platform to verify that it adheres to ethical research standards for social and behavioral sciences.",
        },
        {
          title: "Where do I find research subjects?",
          desc:
            "You may use our subject pool or bring in your own participants. For more information about using our subject pool contact us at: support@gamechanger.solutions",
        },
      ],
      students: [
        {
          title: "How do I get an account?",
          desc: "Register an account using your mobile phone number.",
        },
        {
          title: "What do I use this for?",
          desc:
            "Teachers and researchers use this as a way to deliver research right to your mobile device. No need to come into the clinic or lab!",
        },
        {
          title: "Why do you ask for my demographic info?",
          desc:
            "Questions about vision, dominant hand, and any neuropsychological conditions help teachers and researchers determine in what studies you may participate and how to organize your data.",
        },
        {
          title: "What happens to my data?",
          desc:
            "Security is 100% guaranteed. Your private information is stored digitally in a remote, encrypted database server. Only the teacher or researcher can see your responses to their questions. Also, you can delete your data and your account at any time.",
        },
        {
          title: "Do I get paid?",
          desc:
            "Yes, some researchers offer monetary compensation for participation. Check the terms of your agreement to determine amount and frequency of payment for participation.",
        },
      ],
      currentPage: 6,
      maxPerPage: 2,
      showReadMore: true,
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

