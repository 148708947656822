<template>
    <div class="error-404-area">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="error-404-item">
                        <div class="error-404-img">
                            <div class="scene fill">
                                <div class="expand-width" data-depth="0.2">
                                    <img src="/images/404/1-631x451.png" alt="404">
                                </div>
                            </div>
                        </div>
                        <div class="error-404-content">
                            <h2 class="title">Sorry, this page is not found.</h2>
                            <p class="desc">There are many variations of passages of Lorem Ipsum available majority have
                                suffered alteration in some form.
                            </p>
                            <div class="btn-wrap">
                                <router-link to="/" class="btn btn-custom-size btn-lg extbot-primary-5 blackcurrant-hover">
                                    <i class="icofont-long-arrow-left"></i>
                                    Back to home
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>