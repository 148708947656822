<template>
  <!-- Begin Footer Area -->
  <footer class="footer-style-5">
    <div class="footer-top">
      <div class="container">
        <div class="row gy-5">
          <div class="col-lg-3">
            <div class="footer-item">
              <div class="footer-list contact-list">
                <h2 class="title">Contact Us</h2>
                <ul>
                  <li>
                    <i class="icofont-ui-call"></i>
                    <a href="tel://01-518 755-6022">(+1) 518 755-6022</a>
                  </li>
                  <li>
                    <i class="icofont-envelope-open"></i>
                    <a href="mailto://support@gamechanger.solutions"
                      >support@gamechanger.solutions</a
                    >
                  </li>
                  <li>
                    <i class="icofont-envelope-open"></i>
                    <a href="https://www.linkedin.com/company/gamechanger-research/"
                      >Linkedin</a
                    >
                  </li>
                  <li>
                    <i class="icofont-google-map"></i>
                    <span> 288 Linden Avenue Red Hook, New York USA 12571</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-offset-2">
            <div class="footer-item-wrap row">
              <div class="col-lg-12 col-sm-6">
                <div class="footer-item footer-ps-xl-30">
                  <div class="footer-list">
                    <h2 class="title">Company</h2>
                    <ul>
                      <li><router-link to="/faq">FAQ</router-link></li>
                      <li><router-link to="/contact">Contact Us</router-link></li>
                      <li><router-link to="/term">Term & Condition</router-link></li>
                      <li><router-link to="/contact">Privacy & Policy</router-link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="footer-bottom-item">
              <div class="footer-copyright white-text">
                <span
                  >© GameChanger Research Solutions
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      footerBg: "images/footer/bg/4-1-1920x803.png",
      socials: [
        {
          link: "https://www.facebook.com",
          iconName: "icofont-facebook",
        },
        {
          link: "https://www.skype.com",
          iconName: "icofont-skype",
        },
        {
          link: "https://www.twitter.com",
          iconName: "icofont-twitter",
        },
      ],
    };
  },
};
</script>
